import React, { useEffect, useState } from "react";
import './InfiniteCarousel.css';

function InfiniteCarousel({ listado }) {
    const [started, setStarted] = useState(false);
    function handleStart() {
        setStarted(true);
    }
    useEffect(() => {
        setTimeout(() => {
            handleStart();
        }, 500);
    }, []);
    return <>
        <div className="carousel-track">
            <div className={"carousel-block " + (started ? "start" : "")}>
                {listado.map((pricing, index) => (
                    <img key={index} src={pricing.image} className="carousel-img" alt={""} />
                ))}
            </div>
            <div className={"carousel-block " + (started ? "start" : "")}>
                {listado.map((pricing, index) => (
                    <img key={index} src={pricing.image} className="carousel-img" alt={""} />
                ))}

            </div>
        </div>
    </>
}

export default InfiniteCarousel;