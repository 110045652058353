import React, {  useState } from "react";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Header from "./header";
import Tecnologias from "../../components/Tecnologias/Tecnologias";
import Compromiso from "../../components/Compromiso/Compromiso";
import Clientes from "../../components/Clientes/clientes";
// import Team from "../../components/Team/Team";
// import Proyectos from "../../components/Proyectos/Proyectos";
// import GetStart from "../../components/Valor/GetStart";
//import Blog from "../../components/Blog/Blog";
import Social from "../../components/Redes/Social";
// import Footer from "../../components/Footer/footer";
import FooterLinks from "../../components/Footer/footer-links";
import Partners from "../../components/Partners/Partners";
import Servicios from "../../components/Servicios/service";
import Contacto from "../../components/Contact/Contact";



const Index1 = () => {
  const [state] = useState({
    navItems: [
      { id: 1, idnm: "servicio", navheading: "Servicios" },
      { id: 2, idnm: "partners", navheading: "Partners" },
      { id: 3, idnm: "tecnologias", navheading: "Tecnologias" },
      { id: 4, idnm: "clientes", navheading: "Clientes" },
      // { id: 5, idnm:"proyectos", navheading:"Proyectos"},
      // { id: 5, idnm: "team", navheading: "Equipo" },
      { id: 5, idnm: "contact", navheading: "Contacto" },
    ],
    navClass: "",
  });

  return (
    <React.Fragment>
      {/* Importing Navbar */}
      <NavbarPage navItems={state.navItems} navClass={state.navClass} />

      {/* header */}
      <Header />

      {/* servicios */}
      <Servicios sectionClass="pt-5" background="blanco" />

      {/* logos tecnologias */}
      <Partners background="gris" />

      {/* partners */}
      <Tecnologias background="blanco" />

      {/* clientes */}
      <Clientes background="gris" />

      {/* team */}
      {/* <Team /> */}

      {/* compromiso */}
      <Compromiso />

      {/* testimonial */}
      {/* <Proyectos /> */}

      {/* get started */}
      {/* <GetStart /> */}

      {/* blog */}
      {/* <Blog /> */}

      {/* contacto */}
      <Contacto background="blanco" />

      {/* redes sociales */}
      <Social background="gris" />

      {/* footer */}
      {/* <Footer /> */}
      <FooterLinks/>
      

    </React.Fragment>
  );
};

export default Index1;
